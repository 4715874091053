import '../styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

$(document).ready(function () {
    $('.slider').slick({
        slidesToShow: 3,
        
        easing: 'ease',
        infinite: true,
        responsive: [{
                breakpoint: 1060, // - от какой ширины изменять настройки(1024 и ниже)
                settings: {
                    // вносим изменения на ширине 1024 и ниже 
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 510, // брекпоинтов может быть сколько угодно
                settings: {
                  
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
jQuery('.open-popup-link').magnificPopup({
    type:'inline',
    fixedBgPos: true,
    midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
  });
console.log('webpack starterkit');

function sendGa(category, action, label){
    let obj = {
      category: category,
      action: action, 
      label: label
    }
    parent.postMessage(obj, '*');
  }

let showLib = document.querySelector('.show_lib');
let lib_list =document.querySelector('.lib_list');
let btnYes =document.getElementById('btn_yes');
let popup =document.getElementById('popup_p0');
let overlay = document.getElementById('btn_overlay');
console.log(btnYes);
if(btnYes){
    btnYes.addEventListener("click", function(){
      popup.style.display='none';
      overlay.style.display='none';
    });
}

let medWarm =document.querySelector('.medWarn__more');
let medWarmContent = document.querySelector('.medWarn__content');



if(medWarm){
    medWarm.addEventListener("click", function(){
     
        medWarmContent.style.maxHeight='3000px';
        medWarmContent.style.marginBottom='40px';
 
    });
}



showLib.addEventListener("click", function(){
   
    if(showLib.classList.contains('active')){
        this.classList.remove('active');
        showLib.innerHTML='Скрыть';
        lib_list.classList.remove('disable');
        return;
    }
    showLib.innerHTML='Показать';
    lib_list.classList.add('disable');
    showLib.classList.add('active');


});




var el = $('svg .line_anim');
    

$(window).scroll(function(){
if ( $(this).scrollTop() > el.offset().top - 600 ) {
 el.addClass('play');
 return;

}
});
var el2 = $('svg .line_anim2');
if(el2.length!==0){
     $(window).scroll(function(){
 if ( $(this).scrollTop() > el2.offset().top - 600 ) {
 el2.addClass('play');
 console.log(el2.offset().top);
  }
 });
}


